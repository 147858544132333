import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Brain,
    Menu,
    X,
    MessageSquare
} from 'lucide-react';
import Logo from "../favb.png";

export const Navbar = ({ onHighlightEmail = null }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    const handleLogout = () => {
        if (window.confirm("Are you sure you want to log out?")) {
            localStorage.removeItem('user');
            window.location.reload();
        }
    };

    return (
        <nav className="sticky top-0 z-50 bg-white/90 backdrop-blur-md shadow-sm">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <div className="flex items-center space-x-2 md:space-x-4">
                    <Link to="/" className="flex items-center space-x-2">
{/*                         <Brain className="w-8 h-8 md:w-12 md:h-12 text-blue-600" /> */}
                        <img
                            src={Logo}
                            alt="MDCAT Logo"
                            className="h-10 w-10 md:h-12 md:w-12 rounded-full object-cover"
                        />
                        <h1 className="text-xl md:text-2xl font-bold text-blue-800">MDCAT.ai</h1>
                    </Link>
                </div>

                <div className="hidden md:flex space-x-4 items-center">
                    <Link to="/" className="text-blue-700 hover:text-blue-900 transition">
                        Home
                    </Link>
                    <Link to="/how-it-works" className="text-blue-700 hover:text-blue-900 transition">
                        How It Works
                    </Link>
                    
                    <Link to="/chatbot" className="flex items-center text-blue-700 hover:text-blue-900 transition mr-4">
                        <MessageSquare className="mr-2" /> AI Chatbot
                    </Link>
                    {onHighlightEmail && (
                        <button onClick={onHighlightEmail} className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-900 transition">
                            Join Waitlist
                        </button>
                    )}

                    {localStorage.getItem('user') ? (
                        <button onClick={handleLogout} className="bg-red-600 text-white px-4 py-2 rounded-full hover:bg-red-800 transition">
                            Log Out
                        </button>
                    ) : (
                        <>
                            <Link to="/login" className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-900 transition">
                                Login
                            </Link>
                        </>
                    )}
                </div>

                <div className="md:hidden">
                    <button onClick={toggleMobileMenu} className="text-blue-700 hover:text-blue-900">
                        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
            </div>

            {isMobileMenuOpen && (
                <div className="md:hidden bg-white shadow-md">
                    <div className="flex flex-col items-center space-y-4 py-4">
                        <a href="/" className="text-blue-700 hover:text-blue-900 transition" onClick={toggleMobileMenu}>
                            Home
                        </a>
                        <Link to="/how-it-works" className="text-blue-700 hover:text-blue-900 transition" onClick={toggleMobileMenu}>
                            How It Works
                        </Link>
                        <Link to="/chatbot" className="text-blue-700 hover:text-blue-900 transition flex items-center" onClick={toggleMobileMenu}>
                            <MessageSquare className="mr-2" /> AI Chatbot
                        </Link>
                        {localStorage.getItem('user') ? (
                            <button onClick={handleLogout} className="bg-red-600 text-white px-4 py-2 rounded-full hover:bg-red-800 transition">
                                Log Out
                            </button>
                        ) : (
                            <>
                                <Link to="/login" className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-900 transition" onClick={toggleMobileMenu}>
                                    Login
                                </Link>
                                <Link to="/signup" className="bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-800 transition" onClick={toggleMobileMenu}>
                                    Sign Up
                                </Link>
                            </>
                        )}
                        {onHighlightEmail && (
                            <button onClick={onHighlightEmail} className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition">
                                Join Waitlist
                            </button>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};



export const Footer = () => {
    return (
        <footer className="bg-blue-900 text-white py-8 md:py-12">
            <div className="container mx-auto px-4 text-center">
                <h4 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">Join the MDCAT Revolution</h4>
                <p className="text-base md:text-xl mb-6 md:mb-8">
                    Be the first to transform your medical entrance exam preparation
                </p>
                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                    <button className="bg-white text-blue-900 px-6 py-3 rounded-full hover:bg-blue-100 transition relative group w-full md:w-auto"
                        onClick={() => alert('Coming soon! Join our waitlist.')}>
                        Play Store
                        <div className="absolute bottom-0 left-0 w-full p-2 bg-black text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            Coming Soon! Join our waitlist, we'll notify you.
                        </div>
                    </button>
                    <button className="bg-white text-blue-900 px-6 py-3 rounded-full hover:bg-blue-100 transition relative group w-full md:w-auto"
                        onClick={() => alert('Coming soon! Join our waitlist.')}>
                        App Store
                        <div className="absolute bottom-0 left-0 w-full p-2 bg-black text-white text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            Coming Soon! Join our waitlist, we'll notify you.
                        </div>
                    </button>
                </div>
            </div>
        </footer>
    );
};
