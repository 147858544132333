import React from 'react';
import './FloatingWhatsAppButton.css'; // Add styles for positioning

const FloatingWhatsAppButton = () => {
  return (
    <a
      href="https://whatsapp.com/channel/0029Vaxzt7TBqbrIHkmFUZ17"
      target="_blank"
      rel="noopener noreferrer"
      className="floating-whatsapp-btn"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        style={{ width: '50px', height: '50px' }}
      />
      <span className="whatsapp-tooltip">WhatsApp Channel</span>
    </a>
  );
};

export default FloatingWhatsAppButton;
