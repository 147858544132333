import React from 'react';

// RenameModal.js
const RenameModal = ({
    showRenameModal,
    setShowRenameModal,
    chatToRename,
    newChatName,
    setNewChatName,
    handleRenameChat,
}) => {
    if (!showRenameModal) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg w-full max-w-[90%] md:max-w-sm">
            <h3 className="text-base md:text-lg font-semibold mb-4">Rename Chat</h3>
            <input
                type="text"
                value={newChatName}
                onChange={(e) => setNewChatName(e.target.value)}
                className="w-full px-3 md:px-4 py-2 border rounded text-sm md:text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter new chat name"
            />
            <div className="flex justify-end gap-2 mt-4">
                <button
                    onClick={() => setShowRenameModal(false)}
                    className="px-3 md:px-4 py-2 text-sm md:text-base text-gray-600 hover:text-gray-800"
                >
                    Cancel
                </button>
                <button
                    onClick={handleRenameChat}
                    className="px-3 md:px-4 py-2 text-sm md:text-base bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
    
    );
};


export default RenameModal;
