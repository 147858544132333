import React from 'react';
import { MessageSquare, Smartphone, Save, Home, Menu } from 'lucide-react'; // Added Menu icon
import { useHistory } from 'react-router-dom'; // If you're using React Router for navigation
import { createNewChat } from '../../utils/chatAPI'; // Import the API utility for creating new chat

const WelcomeScreen = ({ onNewChat, setIsSidebarOpen }) => {
    const getAuthData = () => {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            return { user: JSON.parse(userStr) };
        }
        return { user: null };
    };
    const { user } = getAuthData();

    return (
        <div className="w-full px-4 py-0 ">
            {/* Mobile menu button */}
            <button
                className="text-blue-800 absolute top-4 left-4 md:hidden"
                onClick={() => setIsSidebarOpen(true)}
            >
                <Menu size={28} />
            </button>
            
            <div className="text-center space-y-6 mb-12">
                <h1 className="text-3xl lg:text-4xl font-extrabold text-blue-800 inline-block px-8 py-4 bg-gradient-to-r from-blue-100 to-blue-500 rounded-lg shadow-lg">
                    Welcome {user ? user.fullName : 'Student'}!
                </h1>
                <p className="text-xl lg:text-2xl text-gray-700 font-medium">
                    <span className="text-blue-600 font-semibold">MDCAT.ai, </span>
                    Your Trusted Personal Medical Study Assistant
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8 max-w-6xl mx-auto mb-12">
                {/* Feature cards with improved desktop scaling */}
                <div className="bg-white p-6 lg:p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow">
                    <div className="flex flex-col items-center text-center space-y-4">
                        <MessageSquare className="w-12 h-12 lg:w-16 lg:h-16 text-blue-600" />
                        <h2 className="text-xl lg:text-2xl font-semibold">Ask Questions</h2>
                        <p className="text-gray-600 lg:text-lg">Get instant answers to your medical study questions</p>
                    </div>
                </div>
                <div className="bg-white p-6 lg:p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow">
                    <div className="flex flex-col items-center text-center space-y-4">
                        <Save className="w-12 h-12 lg:w-16 lg:h-16 text-blue-600" />
                        <h2 className="text-xl lg:text-2xl font-semibold">Save Your Chats</h2>
                        <p className="text-gray-600 lg:text-lg">Organize discussions with custom names for easy reference</p>
                    </div>
                </div>
                <div className="bg-white p-6 lg:p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow">
                    <div className="flex flex-col items-center text-center space-y-4">
                        <Smartphone className="w-12 h-12 lg:w-16 lg:h-16 text-blue-600" />
                        <h2 className="text-xl lg:text-2xl font-semibold">Access Anywhere</h2>
                        <p className="text-gray-600 lg:text-lg">Continue your studies on our mobile app with the same account</p>
                    </div>
                </div>
            </div>

            <div className="text-center space-y-4">
                <button
                    className="bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition flex items-center space-x-3 mx-auto text-lg"
                    onClick={onNewChat}
                >
                    <MessageSquare className="w-6 h-6" />
                    <span>Start a New Chat</span>
                </button>
                <p className="text-gray-600">or select an existing chat from the list on the left side</p>
            </div>
        </div>
    );
};

export default WelcomeScreen;
