import axios from 'axios';
import config from '../config';  // Import the config file

const NODE_URL = config.NODE_URL

const client = axios.create({
    baseURL: NODE_URL,
});

const getAuthData = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) {
        return { user: JSON.parse(userStr) };
    }
    return { user: null };
};

const createNewChat = async () => {
    const { user } = getAuthData();

    if (!user) {
        throw new Error('User not authenticated');
    }

    try {
        const response = await client.post('/chat/create', {
            chatName: `Chat ${Date.now()}`,  // Using timestamp to name the chat
            userId: user._id,
        });
        if (response.data.success) {
            return response.data.data;  // Returning the created chat data
        } else {
            throw new Error(response.data.message || 'Failed to create new chat');
        }
    } catch (error) {
        console.error('Error creating chat:', error);
        throw new Error('Failed to create new chat');
    }
};

export { createNewChat };
