import React, { useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Loader2, Send,Home } from 'lucide-react';

const ChatWindow = ({
    chatName,
    messages,
    isLoadingMessages,
    isWaitingForReply,
    newMessage,
    setNewMessage,
    handleSendMessage,
    isSidebarOpen,
    setIsSidebarOpen,
}) => {
    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className="flex flex-col h-screen ">
            {/* Header - Improved for desktop */}
            <div className="bg-blue-50 px-4 py-3 border-b flex items-center">
                {/* <button
                    className="text-blue-800 mr-2 md:hidden"
                    onClick={() => setIsSidebarOpen(true)}
                >
                    ☰
                </button> */}
                <div className="flex-1 flex justify-center mx-0 lg:justify-start px-4">
                    <h2 className="font-bold text-blue-800 text-lg truncate max-w-full">
                        {chatName || 'Untitled Chat'}
                    </h2>
                    <div className="relative group ml-auto absolute  right-4">
                        {/* Home Icon */}
                        <a href="/" className="text-blue-600 hover:text-blue-800">
                            <Home size={28} />
                        </a>
                
                        {/* Tooltip */}
                        <div className="absolute left-1/2 transform -translate-x-1/2 top-8 mb-8 px-3 py-2 bg-gray-800 text-white text-sm rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity">
                            Home
                        </div>
                    </div>
                </div>

            </div>

            {/* Messages Container - Better scaling for larger screens */}
            <div className="flex-1 overflow-y-auto px-4 lg:px-8 py-4 space-y-4">
                <div className="max-w-full mx-0">
                    {isLoadingMessages ? (
                        <div className="flex justify-center items-center h-full">
                            <Loader2 className="animate-spin" />
                        </div>
                    ) : (
                        <>
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-0`}
                                >
                                    <div
                                        className={`max-w-[85%] lg:max-w-[75%] p-3 rounded-lg ${
                                            message.sender === 'user' 
                                                ? 'bg-blue-600 text-white' 
                                                : 'bg-blue-50 text-blue-900'
                                        }`}
                                    >
                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                            {message.content}
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            ))}
                            {isWaitingForReply && (
                                <div className="flex justify-start">
                                    <div className="bg-blue-50 text-blue-900 p-3 rounded-lg flex items-center">
                                        <Loader2 className="animate-spin mr-2" />
                                        Typing...
                                    </div>
                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </>
                    )}
                </div>
            </div>

            {/* Input Area - Optimized for desktop */}
            <div className="border-t p-4">
                <div className="max-w-full mx-0 flex items-center gap-2">
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        placeholder="Type your message..."
                        className="flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                        onClick={handleSendMessage}
                        disabled={!newMessage.trim() || isWaitingForReply}
                        className="bg-blue-600 text-white p-2 rounded-full hover:bg-blue-700 transition disabled:opacity-50 w-10 h-10 flex items-center justify-center flex-shrink-0"
                    >
                        <Send className="w-5 h-5" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatWindow;
