import React from 'react';
import {
    MessageSquare,
    X,
    Plus,
    Edit3,
    Trash2,
    Loader2,
    ChevronRight,
    ChevronLeft
} from 'lucide-react';

const Sidebar = ({
    isSidebarOpen,
    setIsSidebarOpen,
    chats,
    loading,
    activeChat,
    handleChatSelect,
    handleNewChat,
    handleOpenRenameModal,
    handleDeleteChat,
}) => (
    <div
        className={`fixed inset-y-0 left-0 bg-blue-50 border-r transform transition-all duration-300 ease-in-out z-40
            ${isSidebarOpen ? 'w-64' : 'w-16'} 
            md:relative md:transform-none md:flex md:flex-col`}
    >
        {/* Header */}
        <div className="p-4 flex justify-between items-center border-b">
            <div className="flex items-center">
                <MessageSquare className="text-blue-600" />
                {isSidebarOpen && (
                    <h2 className="font-bold text-blue-800 ml-2 truncate">Chats</h2>
                )}
            </div>
            <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="text-blue-700 hover:text-blue-900"
            >
                {isSidebarOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
            </button>
        </div>

        {/* New Chat Button */}
        <div className="p-2">
            <button
                onClick={handleNewChat}
                className={`flex items-center justify-center bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition
                    ${isSidebarOpen ? 'w-full px-4 py-2' : 'w-12 h-12 mx-auto'}`}
            >
                <Plus className={isSidebarOpen ? 'mr-2' : ''} />
                {isSidebarOpen && <span>New Chat</span>}
            </button>
        </div>

        {/* Chat List - Removed overflow-y-auto from here */}
        <div className="flex-grow">
            {/* Added a wrapper div with overflow-y-auto */}
            <div className="h-full overflow-y-auto overflow-x-visible">
                {loading ? (
                    <div className="flex justify-center items-center p-4">
                        <Loader2 className="animate-spin" />
                    </div>
                ) : (
                    chats.map((chat) => (
                        <div
                            key={chat._id}
                            className={`p-3 flex items-center cursor-pointer transition-colors relative
                                ${activeChat?._id === chat._id ? 'bg-blue-100' : 'hover:bg-blue-50'}
                                ${isSidebarOpen ? '' : 'justify-center'}`}
                            onClick={() => handleChatSelect(chat)}
                        >
                            {isSidebarOpen ? (
                                <>
                                    <MessageSquare className="text-blue-600 w-5 h-5 flex-shrink-0" />
                                    <div className="flex-grow ml-3 truncate">
                                        <h3 className="font-semibold text-sm truncate">
                                            {chat.chatName}
                                        </h3>
                                        <span className="text-xs text-gray-400">
                                            {new Date(chat.createdAt).toLocaleDateString()}
                                        </span>
                                    </div>
                                    <div className="flex gap-2 ml-2">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenRenameModal(chat);
                                            }}
                                            className="hover:text-blue-600 p-1"
                                        >
                                            <Edit3 size={16} />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteChat(chat._id);
                                            }}
                                            className="hover:text-red-600 p-1"
                                        >
                                            <Trash2 size={16} />
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="relative group">
                                    <MessageSquare 
                                        className={`w-6 h-6 ${
                                            activeChat?._id === chat._id 
                                                ? 'text-blue-600' 
                                                : 'text-gray-600'
                                        }`} 
                                    />
                                    {/* Fixed Tooltip positioning */}
                                    <div 
                                        className="fixed left-16 ml-1 px-3 py-2 bg-gray-800 text-white text-sm rounded
                                            whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity
                                            shadow-lg z-[9999] transform -translate-y-1/2"
                                        style={{
                                            top: '50%',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        {chat.chatName}
                                        <div className="absolute -left-1 top-1/2 -translate-y-1/2 
                                            border-4 border-transparent border-r-gray-800"></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
        </div>
    </div>
);

export default Sidebar;